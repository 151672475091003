import React from "react";
import Layout from "../components/layout";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage} from "gatsby-plugin-image";

const Reviews = () => {
  const dataQuery = useStaticQuery(graphql`
    query Reviews {
      allStrapiFeedback(sort: { order: DESC, fields: sort }) {
        nodes {
          company
          description
          id
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);
  const allData = dataQuery.allStrapiFeedback.nodes;
  return (
    <Layout>
      <div className="container content_header  mt-60">
        <h1 className="text-center">Отзывы:</h1>
        <div className="info mt-30 mb-30 ">
          <p className="p20">
            Вся продукция компании «DENDOR» сертифицирована, имеет «сертификаты
            Высокое качество трубопроводной арматуры DENDOR давно известно в
            России и подтверждено сертификатами ГОСТ РФ.
            <br />
            <br />Арматура DENDOR уже много лет успешно эксплуатируется на таких предприятиях, как ОАО «Группа «Илим», ОАО «Российские Коммунальные Системы», ОАО «НК «Роснефть», ООО «ЛУКОЙЛ», «Филиал «Кока-Кола ЭйчБиСи Евразия» в Санкт-Петербурге, ООО «ПепсиКо Холдингс», ООО «Тиккурила», ООО «Завод Ниссан Мануфэкчуринг Рус», ООО «Хендэ Мотор Мануфактуринг Рус», ООО «ЗАВОД ТОЙОТА МОТОР МАНУФЭКЧУРИНГ РОССИЯ», «Форд Мотор Компани, завод FORD», ОАО «КАМАЗ, ОАО «АвтоВАЗ», Космодром «ВОСТОЧНЫЙ», порт «УСТЬ-ЛУГА» и многих других.
          </p>
        </div>
        <div className="feedback">
          {allData.map((data) => {

            const { company, image, description, id } = data;

            const img = getImage(image.localFile);
            return (
              <div className="feedback__container" key={id}>
                <div className="feedback__company">{company}</div>
                <div className="feedback__img">
                  <GatsbyImage
                    placeholder="#fff"
                    image={img}
                    alt={`отзыв ${company} на продукцию Dendor`}
                  />
                </div>
                <div className="feedback__description">{description}</div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="container mt-30"></div>
    </Layout>
  );
};

export default Reviews;
